import React, { useContext } from "react";
import { workLinks } from "../../../data/templates/work";
import "./work-sider.scss";
import CTA from "../../cta";
import ReactContext from "../../../context/reactContext";

const ProjectsSider = () => {
  const { currentProject } = useContext(ReactContext);

  const {
    liveLink,
    github,
    myRole,
    techStack,
    id,
    logo,
    logoDark,
    title,
    projectName,
    image,
    pageLink,
    description,
    dates,
    team,
    video,
  } = currentProject || {};

  const getNextWorkLink = (currentLink, workLinks) => {
    const currentIndex = workLinks.indexOf(currentLink);
    if (currentIndex === -1) {
      // currentLink not found in workLinks array
      return workLinks[0];
    }

    const nextIndex = (currentIndex + 1) % workLinks.length;
    return workLinks[nextIndex];
  };

  const nextPage = getNextWorkLink(pageLink, workLinks);

  return (
    <div className="db-work-sider">
      <section className="db-work-sider__inner">
        <div className="db-work-sider__title">
          <h3>{projectName}</h3>
        </div>
        <div className="db-work-sider__index">
          <div className="db-work-sider__index__item">
            <h5>ROLE / SERVICES</h5>
            <p>{title}</p>
          </div>
          <div className="db-work-sider__index__item">
            <h5>DATES</h5>
            <p>{dates}</p>
          </div>
          {team && (
            <div className="db-work-sider__index__item db-work-sider__index__item--team">
              <h5>TEAM CREDIT</h5>
              {team.map((member) => (
                <p>{member}</p>
              ))}
            </div>
          )}
        </div>
        <div className="db-work-sider__ctas">
          {liveLink && (
            <div className="db-work-sider__ctas__cta">
              <CTA isOutbound to={liveLink}>
                Live
              </CTA>
            </div>
          )}
          {github && (
            <div className="db-work-sider__ctas__cta">
              <CTA isOutbound to={github}>
                GitHub
              </CTA>
            </div>
          )}
          <div className="db-work-sider__ctas__cta">
            <CTA to="/#db-work">Recent work</CTA>
          </div>
          <div className="db-work-sider__ctas__cta">
            <CTA to={nextPage}>Next project</CTA>
          </div>
          <div className="db-work-sider__ctas__cta">
            <CTA to="/contact">Get in touch</CTA>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectsSider;
