import React, {useContext, useRef, useEffect} from 'react';
import "./sider-testimonials.scss";
import gsap from "gsap";
import ReactContext from '../../../../../context/reactContext';

const SiderTestimonials = () => {
  const { currentQuote, activeSection } = useContext(ReactContext);
  const containerRef = useRef(null);

  useEffect(() => {
    gsap.set(containerRef.current, { height: 0 });
  }, []);

  useEffect(() => {
    if (activeSection === "db-testimonials") {
      gsap.to(containerRef.current, {
        height: "auto",
        duration: 0.3,
        ease: "power2.inOut",
      });
    }
  }, [activeSection]);
  
  return (
    <div className="sider-testimonials">
      <div ref={containerRef} className="sider-testimonials__inner">
        <p>{currentQuote}</p>
      </div>
    </div>
  );
};

export default SiderTestimonials;
