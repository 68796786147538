import React, { useContext, useState } from "react";
import "./sider.scss";
import ReactContext from "../../context/reactContext";
import { navLinks } from "../../data/components/sider";
import { Link } from "../links";
import classNames from "classnames";
import { useLocation } from "@reach/router";
import SiderSwitch from "./siderSwitch";

const Sider = ({ pathname }) => {
  const { menuActive, siderActive, setMenuActive, theme, setIsPrimary, setSiderActive } = useContext(ReactContext);
  const location = useLocation();
  
  const handleClickLink = () => {
    if (!theme) {
      setIsPrimary((prevState) => !prevState);
    }
    setMenuActive(!menuActive);
  };

  const handleSiderMenu = () => {
    setSiderActive((prev) => !prev);
    if (menuActive) {
      setMenuActive(false);
    }
  };


  const siderClasses = classNames("db-sider", {
    "db-sider--closed": !menuActive && !siderActive,
    "db-sider--menu": menuActive && !siderActive,
  });

  const siderInner = !menuActive ? (
    <div className="db-sider__inner"><SiderSwitch pathname={pathname}/></div>
  ) : (
    <div className="db-sider__inner-menu">
      <div className="db-sider__link-index">
        {navLinks.filter((e) => location.pathname !== e.link).map((linkItem) => {
          const { name, link, isOutbound, isAnchor=false } = linkItem;
          const linkClasses = classNames("db-sider__link-index__link cursor-pointer", {
            "db-sider__link-index__link--active": pathname === link,
          });
          const isDownload = name === "Resume" ? true : false;
          return (
            <Link isAnchor={isAnchor} isOutbound={isOutbound} download={isDownload} key={name} className={linkClasses} to={link}>
              <p onClick={() => handleClickLink()}>{name}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
  return <div className={siderClasses}>{siderInner}</div>;
};

export default Sider;
