import React from 'react';
import "./homeSider.scss";
import { anchorIds, anchorLinks } from '../../../data/pages/homepage';
import { AnchorLinkIndex } from "../anchor-link-index";
import useActiveSection from '../../../lib/useActiveSection';
import SiderSectionSwitch from '../sections/section-switch';

const HomeSider = ({ pathname }) => {
  useActiveSection(anchorIds);
  return (
    <div className="db-home-sider">
      <AnchorLinkIndex pathname={pathname} anchorLinks={anchorLinks}/>
      <SiderSectionSwitch/>
    </div>
  );
};

export default HomeSider;
