import React, { useEffect, useRef, useState, useContext } from "react";
import "./logo.scss";
import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import ReactContext from "../../context/reactContext";

gsap.registerPlugin(DrawSVGPlugin);

const Logo = ({ isPrimary, isMobile = false, pathname }) => {
  const { theme } = useContext(ReactContext);

  const animateAllRefs = useRef([]);

  const addToRefs = (el) => {
    if (el && !animateAllRefs.current.includes(el)) {
      animateAllRefs.current.push(el);
    }
  };

  const handleMouseOver = () => {
    animateAllRefs.current.forEach((el) => {
      gsap.set(el, { drawSVG: "0%", duration: 1, ease: "power3" });
    });

    animateAllRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { drawSVG: "0%", duration: 1, ease: "power3" },
        { drawSVG: "100%", duration: 1, ease: "power3" }
      );
    });
  };

  useEffect(() => {
    animateAllRefs.current.forEach((el) => {
      gsap.set(el, { drawSVG: "0%", duration: 1, ease: "power3" });
    });

    animateAllRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { drawSVG: "0%", duration: 1, ease: "power3", delay: 0.3 },
        { drawSVG: "100%", duration: 1, ease: "power3", delay: 0.3 }
      );
    });
  }, [pathname]);

  useEffect(() => {
    animateAllRefs.current.forEach((el) => {
      gsap.set(el, { drawSVG: "0%", duration: 1, ease: "power3" });
    });

    animateAllRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { drawSVG: "0%", duration: 1, ease: "power3" },
        { drawSVG: "100%", duration: 1, ease: "power3" }
      );
    });
  }, [theme]);

  return (
    <div
      className="db-logo cursor-pointer"
      onMouseLeave={() => handleMouseOver()}
      onMouseEnter={() => handleMouseOver()}
    >
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 703.33 85.79"
        className="db-logo__desktop"
      >
        <defs />
        <path
          className="cls-1"
          ref={addToRefs}
          d="m434.92,69.61c-10.86-2.13-19.05-11.7-19.05-23.18v-9.08c0-11.54,8.27-21.14,19.21-23.2"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <path
          className="cls-1"
          ref={addToRefs}
          d="m444.08,14.18c4.69.91,8.88,3.22,12.12,6.47,4.28,4.27,6.92,10.17,6.92,16.7v9.08c0,11.54-8.26,21.15-19.2,23.21"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <path
          className="cls-1"
          ref={addToRefs}
          d="m518.59,28.49c0-8.05-7.96-13.6-16.01-13.6h-4.24c-13.5,0-22.82,12.09-22.82,27h0c0,14.91,9.32,27,22.82,27h8.15c8.18,0,12.1-3.27,12.1-7.31v-19.6s-.04-.09-.09-.09h-20.16"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <polyline
          className="cls-1"
          ref={addToRefs}
          points="78.16 68.9 101.31 14.9 101.31 14.9 125.41 68.9"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <polyline
          className="cls-1"
          ref={addToRefs}
          points="578.88 68.9 555.26 14.9 555.26 14.9 531.63 68.9"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <polyline
          className="cls-1"
          ref={addToRefs}
          points="181.63 14.9 181.63 68.9 141.13 14.9 141.13 68.9"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <line
          className="cls-1"
          ref={addToRefs}
          x1="208.11"
          y1="15.01"
          x2="208.11"
          y2="69.01"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <polyline
          className="cls-1"
          ref={addToRefs}
          points="290 15.01 290 69.01 323.75 69.01"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <line
          className="cls-1"
          ref={addToRefs}
          x1="645.43"
          y1="14.9"
          x2="681"
          y2="14.9"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <line
          className="cls-1"
          ref={addToRefs}
          x1="663.21"
          y1="68.9"
          x2="663.21"
          y2="14.9"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <polyline
          className="cls-1"
          ref={addToRefs}
          points="269.88 14.9 233.59 14.9 233.59 68.9 269.88 68.9"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <line
          className="cls-1"
          ref={addToRefs}
          x1="265.05"
          y1="41.9"
          x2="242.48"
          y2="41.9"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <path
          className="cls-1"
          ref={addToRefs}
          d="m22.33,69.01h17.82c14.91,0,27-12.09,27-27h0c0-14.91-12.09-27-27-27h-17.82"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <line
          className="cls-1"
          ref={addToRefs}
          x1="23.61"
          y1="24.1"
          x2="23.61"
          y2="58.92"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <path
          className="cls-1"
          ref={addToRefs}
          d="m371.33,41.9h13.35c7.46,0,13.5-6.04,13.5-13.5h0c0-7.46-6.04-13.5-13.5-13.5h-23"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <path
          className="cls-1"
          ref={addToRefs}
          d="m371.33,41.9h17.35c7.46,0,13.5,6.04,13.5,13.5h0c0,7.46-6.04,13.5-13.5,13.5h-27"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <line
          className="cls-1"
          ref={addToRefs}
          x1="362.59"
          y1="23.86"
          x2="362.59"
          y2="59.9"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <path
          className="cls-1"
          ref={addToRefs}
          d="m590.93,14.9h27c7.46,0,13.5,6.04,13.5,13.5h0c0,7.46-6.04,13.5-13.5,13.5h-13.5"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <path
          className="cls-1"
          ref={addToRefs}
          d="m631.43,68.9v-13.5h0c0-7.46-6.04-13.5-13.5-13.5h-13.5"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <line
          className="cls-1"
          ref={addToRefs}
          x1="592.23"
          y1="23.86"
          x2="592.23"
          y2="68.9"
          fill="none"
          stroke="var(--primary)"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
      </svg>
    </div>
  );
};

export default React.memo(Logo);
