import React from "react";
import { Link } from "../links";
import { footerLinks } from "../../data/components/footer";
import linkedin from "../../images/linkedin.svg";
import github from "../../images/github.svg";
import Logo from "../../images/DBLogoStroke3.svg";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="db-footer">
      <div className="db-footer__inner">
        <div className="db-footer__logo">
          <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 703.33 85.79"
          >
            <defs />
            <path
              className="cls-1"
              d="m434.92,69.61c-10.86-2.13-19.05-11.7-19.05-23.18v-9.08c0-11.54,8.27-21.14,19.21-23.2"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <path
              className="cls-1"
              d="m444.08,14.18c4.69.91,8.88,3.22,12.12,6.47,4.28,4.27,6.92,10.17,6.92,16.7v9.08c0,11.54-8.26,21.15-19.2,23.21"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <path
              className="cls-1"
              d="m518.59,28.49c0-8.05-7.96-13.6-16.01-13.6h-4.24c-13.5,0-22.82,12.09-22.82,27h0c0,14.91,9.32,27,22.82,27h8.15c8.18,0,12.1-3.27,12.1-7.31v-19.6s-.04-.09-.09-.09h-20.16"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <polyline
              className="cls-1"
              points="78.16 68.9 101.31 14.9 101.31 14.9 125.41 68.9"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <polyline
              className="cls-1"
              points="578.88 68.9 555.26 14.9 555.26 14.9 531.63 68.9"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <polyline
              className="cls-1"
              points="181.63 14.9 181.63 68.9 141.13 14.9 141.13 68.9"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <line
              className="cls-1"
              x1="208.11"
              y1="15.01"
              x2="208.11"
              y2="69.01"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <polyline
              className="cls-1"
              points="290 15.01 290 69.01 323.75 69.01"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <line
              className="cls-1"
              x1="645.43"
              y1="14.9"
              x2="681"
              y2="14.9"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <line
              className="cls-1"
              x1="663.21"
              y1="68.9"
              x2="663.21"
              y2="14.9"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <polyline
              className="cls-1"
              points="269.88 14.9 233.59 14.9 233.59 68.9 269.88 68.9"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <line
              className="cls-1"
              x1="265.05"
              y1="41.9"
              x2="242.48"
              y2="41.9"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <path
              className="cls-1"
              d="m22.33,69.01h17.82c14.91,0,27-12.09,27-27h0c0-14.91-12.09-27-27-27h-17.82"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <line
              className="cls-1"
              x1="23.61"
              y1="24.1"
              x2="23.61"
              y2="58.92"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <path
              className="cls-1"
              d="m371.33,41.9h13.35c7.46,0,13.5-6.04,13.5-13.5h0c0-7.46-6.04-13.5-13.5-13.5h-23"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <path
              className="cls-1"
              d="m371.33,41.9h17.35c7.46,0,13.5,6.04,13.5,13.5h0c0,7.46-6.04,13.5-13.5,13.5h-27"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <line
              className="cls-1"
              x1="362.59"
              y1="23.86"
              x2="362.59"
              y2="59.9"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <path
              className="cls-1"
              d="m590.93,14.9h27c7.46,0,13.5,6.04,13.5,13.5h0c0,7.46-6.04,13.5-13.5,13.5h-13.5"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <path
              className="cls-1"
              d="m631.43,68.9v-13.5h0c0-7.46-6.04-13.5-13.5-13.5h-13.5"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <line
              className="cls-1"
              x1="592.23"
              y1="23.86"
              x2="592.23"
              y2="68.9"
              fill="none"
              stroke="var(--primary)"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
          </svg>
        </div>
        <p className="db-footer__meta-description">
          Hello! My name is Daniel and I'm a frontend software engineer and web
          developer with years of experience building complex web applications
          using the latest technologies such as React.js, Redux.js, JavaScript,
          TypeScript (ect.) with the ability to connect them to a Ruby on Rails
          or MERN style backend.
        </p>
        <div className="db-footer__links">
          {footerLinks.map((item) => {
            const { title, link, isOutbound } = item;
            const isDownload = title === "Resume" ? true : false;
            return (
              <div
                key={title}
                className="db-footer__links__link cursor-pointer"
              >
                <Link isOutbound={isOutbound} download={isDownload} to={link}>
                  {title}
                  <span className="db-footer__links__link__span"></span>
                </Link>
              </div>
            );
          })}
        </div>
        <div className="db-footer__social">
          <div className="db-footer__social__inner">
            <p>Social</p>
            <div className="db-footer__social__links">
              <div className="db-footer__social__link cursor-pointer">
                <Link
                  isOutbound
                  to="https://www.linkedin.com/in/danielrbogart/"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77Z"
                    />
                  </svg>
                </Link>
              </div>
              <div className="db-footer__social__link cursor-pointer">
                <Link isOutbound to="https://github.com/daniel-bogart">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33c.85 0 1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2Z"
                    />
                  </svg>
                </Link>
              </div>
              <div className="db-footer__social__link cursor-pointer">
                <Link isOutbound to="https://www.awwwards.com/daniel-bogart/">
                  <svg width="30" height="16" viewBox="0 0 30 16">
                    <path
                      fill="currentColor"
                      d="m18.4 0-2.803 10.855L12.951 0H9.34L6.693 10.855 3.892 0H0l5.012 15.812h3.425l2.708-10.228 2.709 10.228h3.425L22.29 0h-3.892ZM24.77 13.365c0 1.506 1.12 2.635 2.615 2.635C28.879 16 30 14.87 30 13.365c0-1.506-1.12-2.636-2.615-2.636s-2.615 1.13-2.615 2.636Z"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="db-footer__lower">
          <p>2024 © Daniel R. Bogart. All Rights Reserved.</p>
          {/* <Link to="/terms-and-conditions">Terms & Conditions</Link> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
