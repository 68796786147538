import { useEffect, useContext } from "react";
import ReactContext from "../context/reactContext";

const useActiveSection = (sectionIds, blogPost = false) => {
  const { setActiveSection } = useContext(ReactContext);

  useEffect(() => {
    const handleScroll = () => {
      if (blogPost) {
        let closestH2 = null;
        let closestDistance = Infinity;

        const h2Elements = document.querySelectorAll("h2");

        h2Elements.forEach((h2) => {
          const rect = h2.getBoundingClientRect();
          const distance = Math.abs(rect.top - 100);
          if (distance < closestDistance) {
            closestH2 = h2;
            closestDistance = distance;
          }
        });

        if (closestH2 && closestH2.id) {
          setActiveSection(closestH2.id);
        }
      }
    };

    if (blogPost) {
      window.addEventListener("scroll", handleScroll);
    }

    let sections = sectionIds.map((id) => {
      const element = document.getElementById(id);
      return {
        id,
        element,
        intersectionRatio: 0,
      };
    });

    const handleEntry = (entries) => {
      if (!blogPost) {
        entries.forEach((entry) => {
          const section = sections.find((s) => s.id === entry.target.id);
          if (section) {
            section.intersectionRatio = entry.intersectionRatio;
          }
        });

        const mostVisibleSection = sections.reduce((mostVisible, section) => {
          return section.intersectionRatio > mostVisible.intersectionRatio
            ? section
            : mostVisible;
        });

        setActiveSection(mostVisibleSection.id);
      }
    };

    const observer = new IntersectionObserver(handleEntry, {
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    });

    sections.forEach(({ element }) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      sections.forEach(({ element }) => {
        if (element) {
          observer.unobserve(element);
        }
      });

      if (blogPost) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [sectionIds, setActiveSection, blogPost]);

  // Since the state is held in the React context, no need to return anything.
};

export default useActiveSection;
