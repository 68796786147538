const projects = [
  {
    projectName: "Raw Color Studios",
    id: "db-work__section--raw-color-studios",
    dates: "2024",
    image: "rawColorHome",
    liveLink: "https://www.rawcolorstudios.com/",
    github: null,
    title: "Design & Development",
    pageLink: "/work/raw-color-studios",
    team: null,
    video:
      "https://s3.us-west-2.amazonaws.com/assets.danielrbogart.com/media/rawColorMovie2.mp4",
    description:
      "This site is intended to serve as a brochure and project index for fellow filmmakers, potential partners, and for viewers to watch films produced by the stuido. We wanted it to be beautiful, easily navigable, and to include many thoughtful animations and micro-interactions. Not 'eye candy' but a 'candy trail' for users to keep exploring. Donation page coming soon!",
    myRole: [
      "Used figma to design a complete and responsive mockup of the website including color scheme, buttons, pages, and components based on specific company criteria for a successful user journey.",
      "Established the foundation for project collaboration, version control, secrets, and settings.",
      "Handled DevOps using tools and technologies such as AWS, Github, Cloudflare, etc.",
      "Generated project from scratch, creating style system, layouts, and basic reusable components.",
      " Incorporated custom-made, engaging animations crafted entirely from the ground up to create a user-friendly and interactive atmosphere, ensuring top-tier professionalism and captivating visual appeal.",
      "Made performance optimizations based on updates from google’s Core Web Vitals to increase site visibility and user retention.",
      "Ensured optimal user experience across devices with varying screen sizes.",
    ],
    techStack: [
      "React",
      "JavaScript",
      "TypeScript",
      "Gatsby",
      "SCSS",
      "AWS",
      "Git",
      ,
      "GSAP",
      "GraphQL",
      "Figma",
      "Yarn",
      "HTML",
      "Cloudflare",
    ],
    logo: "rawColorLogoWhite",
    logoDark: "rawColorLogo",
  },
  {
    projectName: "Salusion",
    id: "db-work__section--salusion",
    dates: "2024",
    image: "salusionDisplay",
    liveLink: "https://salusion.com/",
    github: null,
    title: "Design & Development",
    pageLink: "/work/salusion",
    team: null,
    description:
      "At Salusion, I was tasked primarily with desinging and implementing a new onboarding process for HRAs. The idea is that the onboarding process should be simple, provide a clear path for the user to follow, and be engaging. The goal is to make the user feel comfortable and confident in their decision to use Salusion. For Salusion I designed mockups on figma, made improvements to SEO optimization, trimmed the fat of the signup process, and made the site more user friendly.",
    myRole: [
      "Used figma to design a complete and responsive mockup of the website including color scheme, buttons, pages, and components based on specific company criteria for a successful user journey.",
      "Handled DevOps using tools and technologies such as AWS, Github, Cloudflare, etc.",
      "Made performance optimizations based on updates from google’s Core Web Vitals to increase site visibility and user retention.",
      "Ensured optimal user experience across devices with varying screen sizes.",
    ],
    techStack: [
      "React",
      "JavaScript",
      "TypeScript",
      "Docker",
      "Styled Components",
      "Node.js",
      "AWS",
      "Git",
      "Figma",
      "Nginx",
      "MobX",
      "HTML",
      "Cloudflare",
    ],
    logo: "salusion",
    logoDark: "salusion",
  },
  {
    projectName: "Preservation Capital Management",
    id: "db-work__section--preservation-cap",
    dates: "2024",
    image: "pcmHome",
    liveLink: "https://www.preservationcap.com/",
    github: null,
    title: "Design & Development",
    pageLink: "/work/preservation-capital-management",
    team: null,
    video:
      "https://s3.us-west-2.amazonaws.com/assets.danielrbogart.com/media/pcmVideo4.mp4",
    description:
      "This site is intended to serve as a brochure for financial advisors partnered with PCM and their clients to gain insight into their investment strategy, philosophy, and professional background. We wanted it to be simple, easily navigable, and to include many thoughtful animations and micro-interactions to keep a text-heavy site more inviting and interesting. Not 'eye candy' but a 'candy trail' for users to keep exploring. Insights section built with Contentful coming soon!",
    myRole: [
      "Used figma to design a complete and responsive mockup of the website including color scheme, buttons, pages, and components based on specific company criteria for a successful user journey.",
      "Established the foundation for project collaboration, version control, secrets, and settings.",
      "Handled DevOps using tools and technologies such as AWS, Github, Cloudflare, etc.",
      "Generated project from scratch, creating style system, layouts, and basic reusable components.",
      " Incorporated custom-made, engaging animations crafted entirely from the ground up to create a user-friendly and interactive atmosphere, ensuring top-tier professionalism and captivating visual appeal.",
      "Made performance optimizations based on updates from google’s Core Web Vitals to increase site visibility and user retention.",
      "Ensured optimal user experience across devices with varying screen sizes.",
    ],
    techStack: [
      "React",
      "JavaScript",
      "TypeScript",
      "Gatsby",
      "Docker",
      "Tailwind",
      "SCSS",
      "Node.js",
      "Koa",
      "AWS",
      "Git",
      "Hubspot",
      "Ghost",
      "Heap",
      "GSAP",
      "GraphQL",
      "Figma",
      "Storybook",
      "Chromatic",
      "Yarn",
      "HTML",
      "Cloudflare",
    ],
    logo: "pcmBlack",
    logoDark: "pcmWhite",
  },
  {
    projectName: "Timescale",
    id: "db-work__section--timescale",
    dates: "2021-2023",
    image: "timescaleHome",
    liveLink: "https://www.timescale.com/",
    github: null,
    title: "Full-time Web Developer",
    pageLink: "/work/timescale",
    team: null,
    video:
      "https://s3.us-west-2.amazonaws.com/assets.danielrbogart.com/media/sopg.mp4",
    description:
      "Timescale is a technology company specializing in building open-source time-series databases, best known for its flagship product, TimescaleDB, which is an open-source time-series database optimized for fast ingest and complex queries. It is built on top of PostgreSQL offers a solid and scalable solution for managing time-series data.",
    myRole: [
      "Worked closely with designers, PMMs, and other engineers to build, redesign, and maintain pages across all of our web properties including, web-splash, blog, docs, and forum which often involved complex javascript",
      "Lead initiatives and worked with designers to streamline the process for producing ad landing pages. A series of reusable components could be quickly assembled with a variety of options in a matter of minutes once copy was provided from stakeholders.",
      "Handled DevOps using tools and technologies such as AWS, Github, Cloudflare, Docker, etc.",
      "Lead initiatives on finding marketing analytics tools, conducted A/B testing across various portions of our site, and created charts and dashboards using Heap for the marketing team to better analyze user traffic and interactions.",
      "Used Miro and Slab to document development processes and stack architecture for the success of existing/future devs as well as for visibility of ownership for our entire team.",
      "Worked on our backend web-endpoint to update and ensure a seamless submission process for all of our forms as well as integrating a myriad of tools and APIs.",
      "Worked with teams across the board to help plan a major rebrand to the company which involved planning a distributed design system, selecting new technologies to be used, brainstorming best practices for the future of the web engineering team, and giving input to the overall design and feel of the new brand for the website.",
    ],
    techStack: [
      "React",
      "JavaScript",
      "TypeScript",
      "Gatsby",
      "Docker",
      "Tailwind",
      "SCSS",
      "Node.js",
      "Koa",
      "AWS",
      "Git",
      "Hubspot",
      "Ghost",
      "Heap",
      "GSAP",
      "GraphQL",
      "Figma",
      "Storybook",
      "Chromatic",
      "Yarn",
      "HTML",
      "Cloudflare",
    ],
    logo: "timescaleLogoBlack",
    logoDark: "timescaleLogo",
  },
  {
    projectName: "Rollerworld",
    id: "db-work__section--rollerworld",
    dates: "September 2023",
    image: "rollerworld",
    liveLink: "https://www.rollerworldfilm.com/",
    github: null,
    title: "Freelance Web Developer",
    pageLink: "/work/rollerworld",
    team: [
      "Design: Begoña Viladomat",
      "Design: Camila Pons del Toro",
      "Design: Kim Caicedo",
    ],
    video:
      "https://s3.us-west-2.amazonaws.com/assets.danielrbogart.com/media/rollerworld.mp4",
    description:
      "Rollerworld is a thesis film by students from the American Film Institute. The film is a comedy-drama that explores the magic of nostalgia and the enduring strength of family ties. The film is an exploration of letting go while unveiling the well-traveled path immigrants take to success, starting a business.",
    myRole: [
      "Built the entire website from scratch after researching which tools, frameworks, and technologies would be most beneficial based on client needs. I decided to use Gatsby because of optimized performance for static sites, added benefits for SEO, there was no need for routing, and for more control over the build process of the site.",
      "Worked closely with a graphic designer to create a style guide as well as planning pages to maximize reusability while maintaining a modern and visually appealing look to the website.",
      "Set up static site hosting on AWS S3 as well as security/distribution on CloudFlare.",
      "Created a seamless git workflow via Github Actions to enable a dynamic testing environment that creates a unique version of the site for each pull request compared to main that enabled both the client and the designer to view/interact with the site before going to production to provide greater quality assurance.",
      "Designed various animations and responsive aspects to the website",
      "Utilized technologies such as intersection-observer in order to lazy-load images which would decrease the initial load time of the site and provide a better user experience.",
      "Utilized the JavaScript animation library, GSAP, to provide slick, light weight, and interactive animations that provide both a sense of function as well as thoughtfulness to the user experience.",
    ],
    techStack: [
      "React",
      "JavaScript",
      "Gatsby",
      "SCSS",
      "AWS",
      "Git",
      "GSAP",
      "GraphQL",
      "Figma",
      "Yarn",
      "HTML",
      "Cloudflare",
    ],
    logo: "rollerworldBlack",
    logoDark: "rollerWorldWhite",
  },
  {
    projectName: "Claypoole Search",
    id: "db-work__section--claypoole-search",
    dates: "2023-Present",
    image: "claypooleHome",
    liveLink: "https://www.claypoolesearch.com/",
    github: null,
    title: "Freelance Web Developer",
    pageLink: "/work/claypoole-search",
    team: ["Design: Priyanshi Khemka"],
    video:
      "https://s3.us-west-2.amazonaws.com/assets.danielrbogart.com/media/claypoole.mp4",
    description:
      "Claypoole Search is a highly specialized executive search firm with a focus on legal and asset management recruitment. Leveraging a unique network of top law firm partners, general counsels, and C-level HR directors, the firm identifies exceptional candidates, offering unparalleled expertise in legal and compliance departments' organization, best practices, and compensation structures.",
    myRole: [
      "Built the entire website from scratch after researching which tools, frameworks, and technologies would be most beneficial based on client needs. I decided to use Gatsby because of optimized performance for static sites, added benefits for SEO, there was no need for routing, and for more control over the build process of the site.",
      "Worked closely with a graphic designer to create a style guide as well as planning pages to maximize reusability while maintaining a modern and visually appealing look to the website.",
      "Set up static site hosting on AWS S3 as well as security/distribution on CloudFlare.",
      "Created a seamless git workflow via Github Actions to enable a dynamic testing environment that creates a unique version of the site for each pull request compared to main that enabled both the client and the designer to view/interact with the site before going to production to provide greater quality assurance.",
      "Set up an API that would leverage Google Sheets to give more control over content on the site to the clients.",
      "Utilized technologies such as intersection-observer in order to lazy-load images which would decrease the initial load time of the site and provide a better user experience.",
      "Utilized the JavaScript animation library, GSAP, to provide slick, light weight, and interactive animations that provide both a sense of function as well as thoughtfulness to the user experience.",
    ],
    techStack: [
      "React",
      "JavaScript",
      "Gatsby",
      "SCSS",
      "AWS",
      "Git",
      "GSAP",
      "GraphQL",
      "Figma",
      "Yarn",
      "HTML",
      "Cloudflare",
    ],
    logo: "csLogo",
    logoDark: "csLogoWhite",
  },
  {
    projectName: "This site",
    id: "db-work__section--daniel-bogart",
    dates: "2023-Present",
    image: "danielbogart",
    liveLink: "https://www.danielrbogart.com/",
    github: null,
    title: "Web Developer - Designer",
    pageLink: "/work/this-site",
    team: null,
    video: null,
    description:
      "Crafted meticulously from the ground up, this website serves as a vivid testament to both my skillset and my love for exploration in the expansive realm of web development. It's more than just a display of past accomplishments; it's an interactive canvas, showcasing a myriad of advanced features and thoughtful touches.",
    myRole: [
      "Leveraged the use of Contentful as a headless CMS to make it significantly easier and more performant to render content on a fully functioning blog with both an index as well as dynamically rendered individual pages per blog post.",
      "Designed and developed a sider component that serves as a utility bar for nav links, anchor links, various filters, and extra bits of information entirely dependent on which component is being observed in the viewport as well as the page location. It is a highly dynamic system meant to make the site more interactive without feeling like a roadblock.",
      "Designed and built the entire website from scratch after researching which tools, frameworks, and technologies would be most beneficial based on client needs. I decided to use Gatsby because of optimized performance for static sites, added benefits for SEO, there was no need for routing, and for more control over the build process of the site.",
      "Created a seamless git workflow via Github Actions to enable a dynamic testing environment that creates a unique version of the site for each pull request compared to main to view/interact with the site before going to production to provide greater quality assurance.",
      "Leveraged gatsby-browser.js to detect a preference on the user device for dark mode.",
      "Utilized various gatsby plugins and graphql in order to create images that were both highly performant and could be dynamically rendered anywhere on the site.",
      "Utilized the JavaScript animation library, GSAP, to provide slick, light weight, and interactive animations that provide both a sense of function as well as thoughtfulness to the user experience.",
    ],
    techStack: [
      "React",
      "JavaScript",
      "Gatsby",
      "SCSS",
      "AWS",
      "Git",
      "GSAP",
      "GraphQL",
      "Figma",
      "Yarn",
      "HTML",
      "Cloudflare",
      "Contentful",
    ],
    logo: "danielLogo",
    logoDark: "danielLogoWhite",
  },
  {
    projectName: "Danterest",
    id: "db-work__section--danterest",
    dates: "2021",
    image: "danterestHome",
    liveLink: null,
    github: "https://github.com/daniel-bogart/Danterest",
    title: "Fullstack Engineer",
    pageLink: "/work/danterest",
    team: null,
    video: null,
    description:
      "Danterest is a full-stack clone of Pinterest, designed and developed as a portfolio project that I created after graduating App Academy to showcase my proficiency in full-stack development. The project replicates the core functionalities of Pinterest, allowing users to create an account, pin images, create boards, and follow other users. It has been meticulously crafted using the latest technologies, incorporating both front-end and back-end development to create a seamless user experience.",
    myRole: [
      "Architected the foundational design, defining MVPs and blueprinting the database schema from the ground up.",
      "Configured a PostgreSQL database, establishing a Ruby on Rails server infrastructure complemented with optimized routing and Active Record ORM integration.",
      "Initiated a React application from foundational principles, incorporating Redux for efficient global state management and front-end data orchestration.",
      "Implemented robust user authentication mechanisms, deploying token-based validation and secure password encryption strategies.",
      "Designed a search functionality, enabling users to seamlessly locate both personal and communal pins and boards.",
      "Integrated AWS services for dynamic image retrieval and persistent storage solutions.",
      "Provisioned for site deployment on Heroku, though please note the site is not actively hosted at present.",
    ],
    techStack: [
      "React",
      "JavaScript",
      "SCSS",
      "AWS",
      "Git",
      "SQL",
      "Ruby",
      "Ruby on Rails",
      "NPM",
      "HTML",
      "PostgreSQL",
      "Heroku",
      "Redux",
      "Webpack",
    ],
    logo: "danterestLogo",
    logoDark: "danterestLogo",
  },
  {
    projectName: "Banjo Frog",
    id: "db-work__section--banjo-frog",
    dates: "2021",
    image: "banjoFrogGame",
    liveLink: "https://daniel-bogart.github.io/Banjo-Frog/",
    github: "https://github.com/daniel-bogart/Banjo-Frog",
    title: "Frontend Engineer",
    pageLink: "/work/banjo-frog",
    team: null,
    video: null,
    description:
      "Banjo Frog is an arcade-style web-based video game where users can simulate playing a banjo. The gameplay involves five seperate keys on the keyboard representing strings. Each key, correlated to a given string, must be pressed while a vertical note on a downward path crosses a horizontal plain.",
    myRole: [
      "Crafted this project from the ground up, exclusively employing JavaScript and CSS, showcasing a deep understanding of core web technologies.",
      "Engineered gameplay dynamics by introducing multiple win scenarios with each culminating in a distinct ending predicated on the final point tally.",
      "Integrated audio and supplemented the user interface with a toggle feature for seamless sound management.",
    ],
    techStack: ["JavaScript", "Git", "Webpack", "SCSS"],
    logo: "banjoFrogLogo",
    logoDark: "banjoFrogLogo",
  },
  {
    projectName: "Busy Bee",
    id: "db-work__section--busy-bee",
    dates: "2021",
    image: "busyBeeMap",
    liveLink: null,
    github: "https://github.com/aedimoff/BusyBee",
    title: "Fullstack Engineer",
    pageLink: "/work/busy-bee",
    team: [
      "Software Engineer: Arianne Dimoff",
      "Software Engineer: Jackie Marsh",
      "Software Engineer: Patrick Schramm",
    ],
    video: null,
    description:
      "Busy Bee is an effective time-saving tool which allows users to input multiple locations and calculate fastest order of visitation using data from a variety of Google API’s. Google Maps and Places APIs provide map view and search functionality and business information such as ratings, reviews, and store hours, while Google Directions API is used to generate most routes and provide date/time specific data on traffic and road closures. BusyBee is built dynamically, with nearly global application available.",
    myRole: [
      "Spearheaded the entirety of the website's frontend development, encompassing facets ranging from aesthetic design to functional implementations.",
      "Orchestrated a cohesive brand identity, ensuring consistency and resonance throughout the user experience.",
      "Administered intricate HTTP transactions, enabling dynamic content delivery and interactive user experiences.",
      "Constructed specialized components, highlighting features such as saved favorites and a search functionality.",
      "Established a secure user authentication protocol to ensure data integrity and privacy (Please note, the site is not in active hosting at the present moment).",
    ],
    techStack: [
      "React",
      "JavaScript",
      "SCSS",
      "Redux",
      "Express",
      "Node.js",
      "MongoDB",
      "Mongoose",
      "NPM",
      "Git",
      "HTML",
      "Heroku",
      "AWS",
    ],
    logo: "busybeebrown",
    logoDark: "busybeebrown",
  },
];

const workLinks = [
  "/work/raw-color-studios",
  "/work/timescale",
  "/work/rollerworld",
  "/work/claypoole-search",
  "/work/this-site",
  "/work/danterest",
  "/work/banjo-frog",
  "/work/busy-bee",
];

module.exports = { projects, workLinks };
