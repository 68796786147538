import React, { useContext } from "react";
import "./utils.scss";
import ReactContext from "../../../context/reactContext";
import useSound from "use-sound";
import sound from "../../../../static/soundOn.mp3";

const ToggleSound = () => {
  const { hasSound, setHasSound } = useContext(ReactContext);
  const [playActive] = useSound(sound, {
    volume: 0.25,
  });

  const handleToggle = () => {
    if (!hasSound) {
      setHasSound(true);
      playActive();
    } else {
      setHasSound(false);
    };
  };

  return (
    <div
      onClick={() => handleToggle()}
      className="toggle-sound util-icon cursor-pointer"
    >
      {hasSound && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 15 15"
        >
          <path
            fill="var(--bg)"
            d="M8.746 1.065A.5.5 0 0 1 9 1.5v12a.5.5 0 0 1-.757.429L3.362 11H1.5A1.5 1.5 0 0 1 0 9.5V5.497a1.5 1.5 0 0 1 1.5-1.499h1.862l4.88-2.927a.5.5 0 0 1 .504-.006Zm5.108 3.079l-.354-.353l-.707.707l.351.352l.003.002l.02.022a3.194 3.194 0 0 1 .386.597c.22.439.447 1.112.447 2.025c0 .913-.228 1.586-.447 2.025a3.19 3.19 0 0 1-.297.486a1.988 1.988 0 0 1-.11.133l-.002.003l-.351.35l.707.708l.354-.353l-.354-.354l.354.354l.001-.002l.002-.002l.005-.005l.014-.014l.043-.048c.035-.04.082-.097.137-.17c.11-.146.251-.36.391-.639c.28-.56.553-1.386.553-2.472s-.272-1.911-.553-2.472a4.19 4.19 0 0 0-.39-.639a2.89 2.89 0 0 0-.181-.217l-.014-.015l-.005-.005l-.002-.002c0-.001-.002-.002-.355.352l.354-.354Z"
          />
        </svg>
      )}
      {!hasSound && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 15 15"
        >
          <path
            fill="var(--bg)"
            d="M9 1.5a.5.5 0 0 0-.757-.429L3.362 3.998H1.5c-.829 0-1.5.67-1.5 1.5V9.5c0 .829.67 1.5 1.5 1.5h1.862l4.88 2.929A.5.5 0 0 0 9 13.5v-12Zm4.207 5.996l1.414 1.413l-.707.707L12.5 8.203l-1.414 1.413l-.707-.707l1.414-1.413l-1.414-1.413l.707-.707L12.5 6.789l1.415-1.413l.706.707l-1.414 1.413Z"
          />
        </svg>
      )}
    </div>
  );
};

export default ToggleSound;
