import PropTypes from "prop-types";
import React, { useState, useContext, useEffect, useRef } from "react";
import { seoDataObj } from "../data/components/seo";
import withLocation from "../lib/withLocation";
import { Header, Sider, Footer } from "../components/global";
import "./main-layout.scss";
import { SEO } from "../components/seo";
import ReactContext from "../context/reactContext";
import classNames from "classnames";
import Lenis from "@studio-freight/lenis";
import { GlobalCursor } from "../components/custom-cursor";
import useIsDesktop from "../lib/useIsDesktop";
import { ThemeContext } from "../context/themeContext";

const LandingPageLayout = ({
  children,
  location,
  title = "",
  description = "",
  meta = [],
  layout,
}) => {
  const [currentProject, setCurrentProject] = useState(null);
  const [currentWorkImage, setCurrentWorkImage] = useState(null);
  const [hasMusic, setHasMusic] = useState(false);
  const [currentOpenWorkAnchor, setCurrentOpenWorkAnchor] = useState(null);
  const [blogCategory, setBlogCategory] = useState(null);
  const [blogPostAnchorIds, setBlogPostAnchorIds] = useState(null);
  const [currentBlogPost, setCurrentBlogPost] = useState(null);
  const [isPrimary, setIsPrimary] = useState(true);
  const [currentInterest, setCurrentInterest] = useState(null);
  const [modalComponent, setModalComponent] = useState(null);
  const [siderActive, setSiderActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [techFilter, setTechFilter] = useState({ tech: null, type: null });
  const [interestFilter, setInterestFilter] = useState(null);
  const [hasSound, setHasSound] = useState(false);
  const [whichIntro, setWhichIntro] = useState("Introduction");
  const [currentQuote, setCurrentQuote] = useState(
    "I've known Joe Chafkin for many years. He and his Uncle, David, trusted me to build their website from the bottom up to make it modern, fast, and pleasant for clients to look at. I loved working with them every step of the way. They are fun, intelligent, honest, and very hard working people."
  );
  const { pathname } = location;
  const themeContext = useContext(ThemeContext);
  const bodyClass = themeContext.dark ? "dark" : "light";
  const isDesktop = useIsDesktop();

  useEffect(() => {
    // Instantiate Lenis
    const lenis = new Lenis({
      lerp: 0.1, // Adjust for smoother/faster scroll. Default is 0.1
      duration: 1, // Adjust for longer/shorter animation. Default is 1.2
      wheelMultiplier: 1, // Adjust for faster/slower mouse wheel scroll. Default is 1
      touchMultiplier: 2,
      smoothWheel: true,
      // easing: easeOutBack
    });

    // Define the RAF function inside the effect
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    // Start the RAF loop
    const rafId = requestAnimationFrame(raf);

    // Cleanup on component unmount or when dependencies change
    return () => {
      // Remove event listener
      lenis.off("scroll");

      // Cancel the RAF loop
      cancelAnimationFrame(rafId);
    };
  }, []);

  useEffect(() => {
    document.body.className = themeContext.dark ? "dark" : "";
  }, [themeContext.dark]);


  const seoData = seoDataObj[pathname] ||
    seoDataObj[pathname.substr(0, pathname.length - 1)] || {
      title,
      description,
      meta,
    };

  const contentClasses = classNames("db-content", {
    "db-content--black": menuActive,
  });

  return (
    <ReactContext.Provider
      value={{
        currentProject,
        setCurrentProject,
        currentWorkImage,
        setCurrentWorkImage,
        isDesktop,
        hasMusic,
        setHasMusic,
        currentOpenWorkAnchor,
        setCurrentOpenWorkAnchor,
        blogCategory,
        setBlogCategory,
        blogPostAnchorIds,
        setBlogPostAnchorIds,
        currentBlogPost,
        setCurrentBlogPost,
        isPrimary,
        setIsPrimary,
        menuActive,
        setMenuActive,
        activeSection,
        setActiveSection,
        theme: themeContext.dark,
        toggleTheme: themeContext.toggle,
        techFilter,
        setTechFilter,
        currentQuote,
        setCurrentQuote,
        hasSound,
        setHasSound,
        whichIntro,
        setWhichIntro,
        siderActive,
        setSiderActive,
        modalComponent,
        setModalComponent,
        currentInterest,
        setCurrentInterest,
        interestFilter,
        setInterestFilter,
      }}
    >
      <div className={`db-site ${bodyClass}`}>
        {seoData && <SEO {...seoData} />}
        <Header location={location} />
        <div className={contentClasses}>
          <Sider pathname={pathname} />
          <div className="db-main">{children}</div>
        </div>
        <Footer />
      </div>
      {modalComponent}
      <GlobalCursor pathname={pathname}/>
      <div className="static"></div>
    </ReactContext.Provider>
  );
};

LandingPageLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withLocation(LandingPageLayout);
