import React, { useEffect, useState, useRef, useContext } from "react";
import gsap from "gsap";
import "./global-cursor.scss";
import ReactContext from "../../../context/reactContext";
import useSound from "use-sound";
import c1 from "../../../../static/piano-notes/c1.mp3";
import f1 from "../../../../static/piano-notes/f1.mp3";
import gs1 from "../../../../static/piano-notes/gs1.mp3";
import ds1 from "../../../../static/piano-notes/ds1.mp3";
import ds2 from "../../../../static/piano-notes/ds2.mp3";
import cs22 from "../../../../static/piano-notes/cs22.mp3";

const GlobalCursor = ({ pathname }) => {
  const cursorRef = useRef(null);
  const dotRef = useRef(null);
  const [currentColor, setCurrentColor] = useState(0);
  const [currentSound, setCurrentSound] = useState(0);
  const [isCursorPointing, setIsCursorPointing] = useState(false);
  const { hasMusic, isDesktop, theme } = useContext(ReactContext);

  const colors = [
    "#FFB2CE",
    "#BDE6EE",
    "#FBFE67",
    "#DAC5FA",
    "#F55B1C",
    "#029C53",
  ];

  const colorsLight = [
    "#004D31",
    "#421911",
    "#040198",
    "#253A05",
    "#0AA4E3",
    "#FD63AC",
  ];

  const mouseColors = theme ? colors : colorsLight;

  const sounds = [c1, f1, gs1, ds1, f1, f1, ds2, cs22, c1, gs1];

  const [playActive] = useSound(sounds[currentSound], {
    volume: 0.25,
  });

  useEffect(() => {
    const cursor = cursorRef.current;

    gsap.set(cursor, {
      xPercent: -52,
      yPercent: -55,
      backgroundColor: "transparent",
      opacity: 1,
    });

    const handleMouseMove = (e) => {
      gsap.to(cursor, {
        x: e.clientX,
        y: e.clientY,
        duration: 0.5,
        ease: "elastic.out(1, 0.3)",
      });
    };

    const handleMouseDown = () => {
      gsap.killTweensOf(cursor);
      const newScaleValue = isCursorPointing ? 2.6 : 1.4;
      gsap.to(cursor, {
        scale: newScaleValue,
        duration: 0.2,
        ease: "power2.out",
      });
      if (hasMusic) playActive();
      setCurrentSound((prevColorIndex) => (prevColorIndex + 1) % 10);
    };

    const handleMouseUp = () => {
      gsap.killTweensOf(cursor);
      const scaleValue = isCursorPointing ? 2 : 1;
      gsap.to(cursor, {
        scale: scaleValue,
        duration: 0.2,
        ease: "power2.out",
      });
      setCurrentColor((prevColorIndex) => (prevColorIndex + 1) % 6);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [currentColor, isCursorPointing, currentSound, pathname]);

  useEffect(() => {
    const handleMouseOver = () => {
      const cursor = cursorRef.current;
      gsap.to(cursor, {
        scale: 2,
        duration: 0.3,
        ease: "power2.out",
        backgroundColor: mouseColors[currentColor],
        opacity: "66.666%",
      });
      setIsCursorPointing(true);
    };

    const handleMouseOut = () => {
      const cursor = cursorRef.current;
      gsap.to(cursor, {
        scale: 1,
        duration: 0.3,
        ease: "power2.out",
        backgroundColor: "transparent",
        opacity: 1,
      });
      setIsCursorPointing(false);
    };
    const handleMouseOverText = () => {
      const dot = dotRef.current;
      gsap.to(dot, {
        height: 18,
        duration: 0.3,
        ease: "power2.out",
      });
    };

    const handleMouseOutText = () => {
      const dot = dotRef.current;
      gsap.to(dot, {
        height: 2,
        duration: 0.3,
        ease: "power2.out",
      });
    };

    const textElements = document.querySelectorAll(
      "h1, h2, h3, h4, h5, h6, p, input, textarea, li"
    );
    const pointerElements = document.querySelectorAll(".cursor-pointer");
    textElements.forEach((element) => {
      element.addEventListener("mouseover", handleMouseOverText);
      element.addEventListener("mouseout", handleMouseOutText);
    });

    pointerElements.forEach((element) => {
      element.addEventListener("mouseover", handleMouseOver);
      element.addEventListener("mouseout", handleMouseOut);
    });

    return () => {
      pointerElements.forEach((element) => {
        element.removeEventListener("mouseover", handleMouseOver);
        element.removeEventListener("mouseout", handleMouseOut);
      });
      textElements.forEach((element) => {
        element.removeEventListener("mouseover", handleMouseOverText);
        element.removeEventListener("mouseout", handleMouseOutText);
      });
    };
  }, [currentColor, pathname]);

  return (
    <>
      {isDesktop && (
        <div
          style={{ borderColor: mouseColors[currentColor] }}
          ref={cursorRef}
          className="db-global-cursor"
        >
          <span
            ref={dotRef}
            style={{ backgroundColor: mouseColors[currentColor] }}
          ></span>
        </div>
      )}
    </>
  );
};

export default GlobalCursor;
