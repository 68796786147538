export const footerLinks = [
  {
    title: "Work",
    link: "/work",
    isOutbound: false,
  },
  {
    title: "Contact",
    link: "/contact",
    isOutbound: false,
  },
  {
    title: "Blog",
    link: "/blog",
    isOutbound: false,
  },
  {
    title: "Resume",
    link: "/Daniel_Bogart_Resume_2023.pdf",
    isOutbound: true,
  },
  {
    title: "About",
    link: "/about",
    isOutbound: false,
  },
  {
    title: "Home",
    link: "/",
    isOutbound: false,
  },
];
