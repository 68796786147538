export const seoDataObj = {
  "": {
    description:
      "Daniel Bogart - Frontend Software Engineer and Web Developer in Los Angeles. Explore my portfolio and discover my web development journey.",
    title:
      "Daniel Bogart | Experienced Frontend Software Engineer in Los Angeles",
    // image: "",
  },
  "/about": {
    description:
      "Learn more about Daniel Bogart's journey from Santa Barbara to a becoming a web developer. Discover my story, travels, and passion for coding.",
    title: "About Daniel Bogart - My Journey and Passion for Web Development",
    // image: "",
  },
  "/work": {
    description:
      "A showcase of Daniel Bogart's web development projects. Dive into my portfolio and explore my experience from bootcamp to fulltime work to freelancing.",
    title: "Daniel Bogart's Web Development Portfolio",
    // image: "",
  },
  "/blog": {
    description:
      "Daniel Bogart's insights on web development topics such as React, Gatsby, DevOps, Design, and more. Explore articles and elevate your knowledge.",
    title: "Daniel Bogart's Blog - Web Development Insights & Tutorials",
    // image: "",
  },
};
