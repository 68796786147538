import React, { useContext, useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { projects } from "../../../../../data/pages/work";
import ReactContext from "../../../../../context/reactContext";
import classNames from "classnames";
import gsap from "gsap";
import { assetArrayToObject } from "../../../../../lib/graphql-helpers";
import { useCheckScreenSize } from "../../../../../lib/checkScreenSize";
import "./work-sider.scss";

const WorkSiderHome = () => {
  const isMobile = useCheckScreenSize(790);
  const containerRef = useRef(null);
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg|png|svg)/" }
          relativeDirectory: { eq: "work/display" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 480, placeholder: BLURRED)
              dominantColor: gatsbyImageData(layout: FIXED, width: 1, height: 1)
            }
          }
        }
      }
    }
  `);
  const scrollContainerRef = useRef(null);
  const { currentWorkImage, activeSection } = useContext(ReactContext);

  useEffect(() => {
    gsap.set(containerRef.current, { height: 0 });
  }, []);

  useEffect(() => {
    if (activeSection === "db-work") {
      gsap.to(containerRef.current, {
        height: "auto",
        duration: 0.3,
        ease: "power2.inOut",
      });
    }
  }, [activeSection]);

  const colors = [
    "#FFB2CE",
    "#BDE6EE",
    "#FBFE67",
    "#DAC5FA",
    "#F55B1C",
    "#029C53",
  ];

  const {
    allFile: { edges: allImages },
  } = data;
  const workPageImages = assetArrayToObject({
    useGatsbyImage: true,
    images: allImages,
  });

  useEffect(() => {
    if (!isMobile) {const currentImageId = document.getElementById(
      `preview-${currentWorkImage}`
    );
    if (currentWorkImage && scrollContainerRef.current) {
      const destination =
        currentImageId.offsetTop + 214 - scrollContainerRef.current.offsetTop;

      gsap.to(scrollContainerRef.current, {
        duration: 0.4, // the time the animation will take, in seconds
        scrollTop: destination,
        ease: "power2.inOut", // easing function for the animation
      });
    }}
  }, [currentWorkImage]);

  
  return (
    <div ref={containerRef} className="work-sider-home">
      <div ref={scrollContainerRef} className="work-sider-home__index">
        {!isMobile &&
          projects.map((project, index) => {
            const { image } = project;
            const imageClasses = classNames("work-sider-home__image", {
              "work-sider-home__image--active": currentWorkImage === image,
            });
            return (
              <div id={`preview-${image}`} key={image} className={imageClasses}>
                <div className="work-sider-home__image__wrapper">
                  <GatsbyImage
                    imgStyle={{ objectFit: "cover", objectPosition: "50% 50%" }}
                    image={workPageImages[image]}
                    alt={image}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default WorkSiderHome;
