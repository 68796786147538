import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { useGesture } from "react-use-gesture";
import ToggleSound from "./sound";
import DarkMode from "./dark-mode";
import ToggleMusic from "./music";
import { Link } from "../../links";
import "./utils.scss";
import classNames from "classnames";

const HeaderUtils = () => {
  const containerRef = useRef(null);
  const [menuActive, setMenuActive] = useState(false);

  const bind = useGesture({
    onMove: ({ xy: [x, y] }) => {
      const container = containerRef.current;
      const icons = container.children;

      for (let i = 0; i < icons.length; i++) {
        const icon = icons[i];
        const rect = icon.getBoundingClientRect();
        const dx = x - (rect.left + rect.width / 2);
        const dy = y - (rect.top + rect.height / 2);
        const distance = Math.sqrt(dx * dx + dy * dy);
        const calculatedScale = Math.pow(
          gsap.utils.mapRange(0, 200, 1.3, 1, distance),
          2
        );
        const scale = Math.max(1, calculatedScale);

        gsap.to(icon, {
          scale: scale,
          duration: 0.3,
          ease: "power2.out",
        });
      }
    },

    onHover: ({ hovering }) => {
      if (!hovering) {
        gsap.to(containerRef.current.children, { scale: 1, duration: 0.3 });
      }
    },
  });

  useEffect(() => {
    return () => {
      if (containerRef.current) {
        gsap.to(containerRef.current.children, { scale: 1 });
      }
    };
  }, []);

  const handleMenuClick = () => {
    if (containerRef.current && !menuActive) {
      setMenuActive(true);
      Array.from(containerRef.current.children).forEach((item, index) => {
        gsap.to(item, {
          opacity: 1,
          ease: "power4.out",
          duration: 0.35,
          y: -index * 64,
        });
      });
    } else {
      setMenuActive(false);
      Array.from(containerRef.current.children).forEach((item, index) => {
        gsap.to(item, {
          opacity: 0,
          duration: 0.35,
          ease: "power4.out",
          y: 0,
        });
      });
      return () => {
        gsap.killTweensOf(containerRef.current.children);
      };
    }
  };

  const utilIndexClasses = classNames("db-header__utils", {
    "db-header__utils--active": menuActive,
  });

  return (
    <div className={utilIndexClasses} ref={containerRef} {...bind()}>
      <div
        className="util-icon--menu cursor-pointer"
        onClick={() => handleMenuClick()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 14 14"
        >
          <g
            fill="none"
            stroke="var(--bg)"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="7" cy="2.5" r="1.5" />
            <circle cx="2" cy="11.5" r="1.5" />
            <circle cx="7" cy="11.5" r="1.5" />
            <circle cx="12" cy="11.5" r="1.5" />
            <path d="M2 10V8a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2M7 4v6" />
          </g>
        </svg>
      </div>
      <DarkMode />
      <ToggleSound />
      <ToggleMusic />
      <div className="util-icon cursor-pointer">
        <Link to="https://www.linkedin.com/in/danielrbogart/" isOutbound>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77Z"
            />
          </svg>
        </Link>
      </div>
      <div className="util-icon cursor-pointer">
        <Link to="https://github.com/daniel-bogart" isOutbound>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33c.85 0 1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2Z"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default HeaderUtils;
