import React, { useContext, useState, useRef, useEffect } from "react";
import gsap from "gsap";
import { Link } from "../../links";
import ReactContext from "../../../context/reactContext";
import "./anchorLinksIndex.scss";
import { useCheckScreenSize } from "../../../lib/checkScreenSize";
import classNames from "classnames";

const AnchorLinkIndex = ({ anchorLinks, clickFunc = false, pathname }) => {
  const isMobile = useCheckScreenSize(600);
  const { activeSection, theme } = useContext(ReactContext);
  const [dropArrowActive, setDropArrowActive] = useState(isMobile);
  const anchorLinksRef = useRef(null);

  const sectionColorMap = {
    ...(anchorLinks[0] ? { [anchorLinks[0].link]: "#FFB2CE" } : {}),
    ...(anchorLinks[1] ? { [anchorLinks[1].link]: "#BDE6EE" } : {}),
    ...(anchorLinks[2] ? { [anchorLinks[2].link]: "#FBFE67" } : {}),
    ...(anchorLinks[3] ? { [anchorLinks[3].link]: "#DAC5FA" } : {}),
    ...(anchorLinks[4] ? { [anchorLinks[4].link]: "#F55B1C" } : {}),
    ...(anchorLinks[5] ? { [anchorLinks[5].link]: "#029C53" } : {}),
    ...(anchorLinks[6] ? { [anchorLinks[6].link]: "#FFB2CE" } : {}),
    ...(anchorLinks[7] ? { [anchorLinks[7].link]: "#BDE6EE" } : {}),
    ...(anchorLinks[8] ? { [anchorLinks[8].link]: "#FBFE67" } : {}),
    ...(anchorLinks[9] ? { [anchorLinks[9].link]: "#DAC5FA" } : {}),
    ...(anchorLinks[10] ? { [anchorLinks[10].link]: "#F55B1C" } : {}),
    ...(anchorLinks[11] ? { [anchorLinks[11].link]: "#029C53" } : {}),
  };

  const anchorLinksStyle = {
    background: `linear-gradient(to bottom, ${
      sectionColorMap[`#${activeSection}`]
    }, rgba(255,185,255, 0.08))`,
  };

  const dropArrowClassses = classNames(
    "anchor-links__drop-arrow cursor-pointer",
    {
      "anchor-links__drop-arrow--active": dropArrowActive,
    }
  );

  const handleDropdown = () => {
    if (!dropArrowActive) {
      setDropArrowActive(true);
    } else {
      setDropArrowActive(false);
    }
  };

  const currentItem = anchorLinks.find((el) => el.link === `#${activeSection}`);

  useEffect(() => {
    const closedHeight =
      activeSection &&
      anchorLinksRef.current.children[0].children[0].offsetHeight
        ? anchorLinksRef.current.children[0].children[0].offsetHeight
        : 68;
    const fullHeight = anchorLinksRef.current.children[0].offsetHeight;
    if (activeSection === "db-work") {
      setDropArrowActive(false);
    }
    if (dropArrowActive) {
      gsap.to(anchorLinksRef.current, {
        height: closedHeight + 40,
        ease: "power1.out",
        duration: 0.3,
      });
    } else {
      gsap.to(anchorLinksRef.current, {
        height: fullHeight,
        ease: "power1.out",
        duration: 0.3,
      });
    }

  }, [dropArrowActive, activeSection, isMobile]);

  const dropLinkClasses = classNames("anchor-links__link cursor-pointer", {
    "anchor-links__link--active":
      `#${activeSection}` === currentItem && currentItem.link,
    "anchor-links__link--active--dark":
      `#${activeSection}` === currentItem && currentItem.link && theme,
    "anchor-links__link--dark": theme,
  });

  return (
    <section
      ref={anchorLinksRef}
      style={anchorLinksStyle}
      className="anchor-links"
    >
      <div className="anchor-links__inner">
        {!dropArrowActive &&
          anchorLinks.map((item) => {
            const { name, link } = item;
            const linkClasses = classNames(
              "anchor-links__link cursor-pointer",
              {
                "anchor-links__link--active": `#${activeSection}` === link,
                "anchor-links__link--active--dark":
                  `#${activeSection}` === link && theme,
                "anchor-links__link--dark": theme,
              }
            );
            return (
              <div
                key={name}
                onClick={() => clickFunc && clickFunc(link)}
                className={linkClasses}
              >
                <Link isAnchor={true} to={link}>
                  <p>{name}</p>
                </Link>
              </div>
            );
          })}
        {dropArrowActive && currentItem && (
          <div className={dropLinkClasses}>
            <Link key={currentItem.name} isAnchor={true} to={currentItem.link}>
              <p>{currentItem.name}</p>
            </Link>
          </div>
        )}
        {dropArrowActive && !currentItem && !activeSection && (
          <div className={dropLinkClasses}>
            <Link
              key={anchorLinks[0].name}
              isAnchor={true}
              to={anchorLinks[0].link}
            >
              <p>{anchorLinks[0].name}</p>
            </Link>
          </div>
        )}
      </div>
      {(activeSection !== "db-work") && <div
        onClick={() => handleDropdown()}
        className={`${dropArrowClassses} cursor-pointer`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--bg)"
            d="M13 7.828V20h-2V7.828l-5.364 5.364l-1.414-1.414L12 4l7.778 7.778l-1.414 1.414L13 7.828Z"
          />
        </svg>
      </div>}
    </section>
  );
};

export default AnchorLinkIndex;
