import "./src/styles/global.scss";
import packageInfo from "./package.json";
import React, {useState, useEffect} from "react";
import { ThemeContext } from "./src/context/themeContext";

if (typeof window !== "undefined") {
  window.portfolio = window.portfolio || {};
  window.portfolio.version = packageInfo.version;
}

export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
  const { location } = routerProps;

  // Don't scroll to top if it's an anchor link
  if (location.hash) {
    return false;
  }

  // Existing logic for other route transitions
  if (routerProps.location.action === "PUSH") {
    window.scrollTo(0, 0);
  } else {
    const savedPosition = getSavedScrollPosition(routerProps.location);
    window.scrollTo(...(savedPosition || [0, 0]));
  }

  return false;
};


const RootElement = ({ element }) => {
  const [dark, setDark] = useState(false); // default theme is light

  // Detect system theme setting on first render
  useEffect(() => {
    const userPrefersDark =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;

    // if the user prefers a dark theme, change state to reflect that
    if (userPrefersDark) {
      setDark(true);
    }
  }, []); // Empty array ensures effect is only run on mount

  return (
    <ThemeContext.Provider
      value={{
        dark,
        toggle: () => {
          localStorage.setItem("darkTheme", JSON.stringify(!dark));
          setDark(!dark);
        },
      }}
    >
      {element}
    </ThemeContext.Provider>
  );
};

export const wrapRootElement = ({ element }) => {
  return <RootElement element={element} />;
};
