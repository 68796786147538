import React, { useContext } from "react";
import ReactContext from "../../../context/reactContext";
import { SiderTechStack, SiderTestimonials, SiderIntro, WorkSiderHome } from "./homepage";
import SiderInterests from "./about/sider-interests";

const SiderSectionSwitch = () => {
  const { activeSection } = useContext(ReactContext);

  const sectionComponentMap = {
    "db-tech-stack": () => <SiderTechStack />,
    "db-testimonials": () => <SiderTestimonials />,
    "db-work": () => <WorkSiderHome />,
    // "db-introduction": () => <SiderIntro/>,
    "db-about__interests": () => <SiderInterests />,
    //... add more mappings here as necessary
  };

  const renderComponent = sectionComponentMap[activeSection] || (() => null);

  return renderComponent();
};

export default SiderSectionSwitch;
