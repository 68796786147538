import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import "../homepage/homeSider.scss";
import { AnchorLinkIndex } from "../anchor-link-index";
import useActiveSection from "../../../lib/useActiveSection";
import ReactContext from "../../../context/reactContext";
import { Link } from "../../links";

const BlogPostSider = () => {
  function decodeString(str) {
    let decoded = decodeURIComponent(str);
    decoded = decoded
      .replace(/-/g, " ")
      .replace(/%3A/g, ":")
      .replace(/_/g, ".")
      .replace(/ question/g, "?");
    return decoded.replace(/^section/, "");
  }
  const { blogPostAnchorIds } = useContext(ReactContext);
  const blogPostAnchorLinks = () => {
    const linksArray = [];
    blogPostAnchorIds.forEach((item) => {
      const linkItem = {
        id: uuidv4(),
        name: decodeString(item),
        link: `#${item}`,
      };
      linksArray.push(linkItem);
    });
    return linksArray;
  };
  const structuredLinkInfo = blogPostAnchorLinks();
  useActiveSection(blogPostAnchorIds, true);
  return (
    <div className="db-home-sider">
      <AnchorLinkIndex anchorLinks={structuredLinkInfo} />
      <Link to="/blog" className="db-home-sider__blog-explore cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 20 20"
        >
          <path
            fill="var(--primary)"
            d="m3.828 9l6.071-6.071l-1.414-1.414L0 10l.707.707l7.778 7.778l1.414-1.414L3.828 11H20V9H3.828z"
          />
        </svg>
        <h3>Explore more posts</h3>
      </Link>
    </div>
  );
};

export default BlogPostSider;
