import { useEffect, useState } from "react";

const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const detectDevice = () => {
      const userAgent =
        typeof window.navigator === "undefined" ? "" : navigator.userAgent;
      const mobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );
      if (!mobile) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    };

    detectDevice();
  }, []);

  return isDesktop;
};

export default useIsDesktop;
