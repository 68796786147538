import React from 'react';
import "./about-sider.scss";
import SiderSectionSwitch from '../sections/section-switch';
import { anchorLinks, anchorIds } from '../../../data/pages/about';
import useActiveSection from '../../../lib/useActiveSection';
import { AnchorLinkIndex } from '../anchor-link-index';

const AboutSider = () => {
  useActiveSection(anchorIds);
  return (
    <div className="db-about-sider">
      <AnchorLinkIndex anchorLinks={anchorLinks}/>
      <SiderSectionSwitch/>
    </div>
  );
};

export default AboutSider;
