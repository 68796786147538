import React, { useContext } from "react";
import "./utils.scss";
import ReactContext from "../../../context/reactContext";
import useSound from "use-sound";
import c1 from "../../../../static/piano-notes/c1.mp3";

const ToggleMusic = () => {
  const { hasMusic, setHasMusic } = useContext(ReactContext);
  const [playActive] = useSound(c1, {
    volume: 0.25,
  });

  const handleToggle = () => {
    if (!hasMusic) {
      setHasMusic(true);
      playActive();
    } else {
      setHasMusic(false);
    }
  };

  return (
    <div
      onClick={() => handleToggle()}
      className="toggle-music util-icon cursor-pointer"
    >
      {hasMusic && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--bg)"
            d="M5 21q-.825 0-1.413-.588T3 19V5q0-.825.588-1.413T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.588 1.413T19 21H5Zm0-2h3.25v-4.5H8q-.425 0-.713-.288T7 13.5V5H5v14Zm10.75 0H19V5h-2v8.5q0 .425-.288.713T16 14.5h-.25V19Zm-6 0h4.5v-4.5H14q-.425 0-.713-.288T13 13.5V5h-2v8.5q0 .425-.288.713T10 14.5h-.25V19Z"
          />
        </svg>
      )}
      {!hasMusic && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--bg)"
            d="M19.775 22.625L18.15 21H5q-.825 0-1.413-.588T3 19V5.85L1.375 4.225L2.8 2.8l18.4 18.4l-1.425 1.425ZM21 18.15l-2-2V5h-2v8.5q0 .125-.038.25t-.112.25L13 10.15V5h-2v3.15L5.85 3H19q.825 0 1.413.588T21 5v13.15ZM5 19h3.25v-4.5H8q-.425 0-.713-.288T7 13.5V9.85l-2-2V19Zm4.75 0h4.5v-1.9l-3.3-3.3q-.1.325-.362.513T10 14.5h-.25V19Zm6 0h.4l-.4-.4v.4Z"
          />
        </svg>
      )}
    </div>
  );
};

export default ToggleMusic;
