import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "@reach/router";
import { anchorLinks } from "../../data/pages/homepage";
import "./header.scss";
import Logo from "../logo/logo";
import { Link } from "../links";
import classNames from "classnames";
import { groups } from "../../data/components/header";
import { HeaderUtils } from "./header-utils";
import ReactContext from "../../context/reactContext";

const Header = () => {
  const location = useLocation();
  const {
    menuActive,
    setMenuActive,
    setSiderActive,
    siderActive,
    theme,
    isPrimary,
    setIsPrimary,
  } = useContext(ReactContext);
  const { pathname } = location;

  const logoClasses = classNames("db-header__logo", {
    "db-header__logo--white": !isPrimary && !theme && menuActive,
    // "db-header__logo": isPrimary && !theme,
  });

  const hamburgerClassses = classNames("db-header__hamburger cursor-pointer", {
    "db-header__hamburger--active": menuActive,
  });

  const siderMenuClassses = classNames("db-header__sider-menu", {
    "db-header__sider-menu--active": siderActive,
  });

  const pathClasses = classNames("db-header__logo__path cursor-pointer", {
    "db-header__logo__path--black":
      theme || (!isPrimary && !theme && menuActive),
  });


  useEffect(() => {
    if (!theme) {
      menuActive ? setIsPrimary(false) : setIsPrimary(true);
    }
    if (menuActive) {
      setIsPrimary(false);
    }
  }, [isPrimary, theme]);

  const handleHamburgerMenu = () => {
    if (!theme) {
      setIsPrimary((prevState) => !prevState);
    }
    setMenuActive(!menuActive);
  };

  const handleSiderMenu = () => {
    setSiderActive((prev) => !prev);
    if (menuActive) {
      setMenuActive(false);
    }
  };

  const currentPathMap = {
    "/": "Home",
    "/blog": "Blog",
    "/about": "About",
    "/work": "Work",
    "/contact": "Contact",
  }

  return (
    <div className="db-header">
      <div className="db-header__inner">
        <div className={logoClasses}>
          {currentPathMap[location.pathname] && (
            <h2 onClick={() => handleHamburgerMenu()} className={pathClasses}>
              {currentPathMap[location.pathname]}
            </h2>
          )}
          <button
            className={hamburgerClassses}
            onClick={() => handleHamburgerMenu()}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <div className="db-header__right">
          <div className="db-header__contacts">
            <Link to="/">
              <Logo pathname={pathname} isPrimary={isPrimary} />
            </Link>
          </div>
          <HeaderUtils />
          <div onClick={() => handleSiderMenu()} className={siderMenuClassses}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 20 20"
            >
              <path
                fill="var(--bg)"
                d="m16.172 9l-6.071-6.071l1.414-1.414L20 10l-.707.707l-7.778 7.778l-1.414-1.414L16.172 11H0V9z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
