import React, { useContext, useEffect, useRef } from "react";
import "./section-tech-stack.scss";
import gsap from "gsap";
import ReactContext from "../../../../../context/reactContext";
import { techStackTypes } from "../../../../../data/pages/homepage";
import classNames from "classnames";

const SiderTechStack = () => {
  const { theme, setTechFilter, techFilter, activeSection } = useContext(ReactContext);
  const siderRef = useRef(null);
  const containerRef = useRef(null);

  const { techs, types } = techStackTypes;

  useEffect(() => {
    gsap.set(containerRef.current, { height: 0, opacity: 0 });
  }, []);

  useEffect(() => {
    if (activeSection === "db-tech-stack") {
      gsap.to(containerRef.current, {
        height: "auto",
        duration: 0.3,
        ease: "power2.inOut",
        opacity: 1,
      });
    }
  }, [activeSection]);

  return (
    <div ref={siderRef} className="sider-tech-stack">
      <div ref={containerRef} className="sider-tech-stack__filter">
        <h2>Filter by:</h2>
        <div className="sider-tech-stack__filter__types">
          {types.map((el, index) => {
            const buttonClasses = classNames(
              "sider-tech-stack__filter__button cursor-pointer",
              {
                "sider-tech-stack__filter__button--active":
                  el === techFilter.type,
              }
            );
            const newFilter = el !== techFilter.type ? el : null;
            return (
              <button
                className={buttonClasses}
                key={el}
                onClick={() =>
                  setTechFilter((prevState) => ({
                    ...prevState,
                    type: newFilter,
                  }))
                }
              >
                {el}
              </button>
            );
          })}
        </div>
        <div className="sider-tech-stack__filter__types">
          {techs.map((el, index) => {
            const buttonClasses = classNames(
              "sider-tech-stack__filter__button cursor-pointer",
              {
                "sider-tech-stack__filter__button--active":
                  el === techFilter.tech,
              }
            );
            const newFilter = el !== techFilter.tech ? el : null;
            return (
              <button
                className={buttonClasses}
                key={el}
                onClick={() =>
                  setTechFilter((prevState) => ({
                    ...prevState,
                    tech: newFilter,
                  }))
                }
              >
                {el}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SiderTechStack;
