export const navLinks = [
  {
    name: "Home",
    link: "/",
    isOutbound: false,
  },
  {
    name: "Work",
    link: "/#db-work",
    isOutbound: false,
  },
  {
    name: "Blog",
    link: "/blog",
    isOutbound: false,
  },
  {
    name: "About",
    link: "/about",
    isOutbound: false,
  },
  {
    name: "Resume",
    link: "/Daniel_Bogart_Resume_2023.pdf",
    isOutbound: true,
  },
  {
    name: "Contact",
    link: "/contact",
    isOutbound: false,
  },
];
