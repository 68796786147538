import React from "react";
import { Link } from "../../links";
import "./contact-sider.scss";

const ContactSider = () => {
  return (
    <div className="contact-sider">
      <div className="contact-sider__inner">
        <div className="contact-sider__content">
          <h5>CONTACT DETAILS</h5>
          <div className="contact-sider__content__links cursor-pointer">
            <Link isOutbound to="mailto:daniel.bogart17@gmail.com">
              daniel.bogart17@gmail.com
              <span></span>
            </Link>
            <p>+18058610528</p>
          </div>
        </div>
        <div className="contact-sider__content">
          <h5>SOCIALS</h5>
          <div className="contact-sider__content__links cursor-pointer">
            <Link isOutbound to="https://www.linkedin.com/in/danielrbogart/">
              Linkedin
              <span></span>
            </Link>
            <Link to="https://github.com/daniel-bogart" isOutbound>
              Github
              <span></span>
            </Link>
          </div>
        </div>
        <div className="contact-sider__content">
          <h5>LOCATION</h5>
          <div className="contact-sider__content__links">
            <p>Los Angeles, California</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSider;
