import React, { useContext } from "react";
import ReactContext from "../../../context/reactContext";
import classNames from "classnames";
import "./blogSider.scss";

const categories = [
  "Animations",
  "DevOps",
  "Gatsby",
  "Performance",
  "React",
  "Styling",
  "Career",
  "Design",
];

const BlogSider = () => {
  const { blogCategory, setBlogCategory, theme } = useContext(ReactContext);
  const clearClasses = classNames("db-blog-sider__category", {
    "db-blog-sider__category--dark": !theme,
  });
  return (
    <div className="db-blog-sider">
      <div className="db-blog-sider__inner">
        <h2>Categories</h2>
        {categories.map((cat) => {
          const categoryClasses = classNames(
            "db-blog-sider__category cursor-pointer",
            {
              "db-blog-sider__category--dark": !theme,
              "db-blog-sider__category--active": cat === blogCategory,
              "db-blog-sider__category--active--dark":
                blogCategory === cat && !theme,
            }
          );
          return (
            <div
              key={cat}
              onClick={() =>
                setBlogCategory((prevState) => (prevState === cat ? null : cat))
              }
              className="db-blog-sider__category--container"
            >
              <h3 className={categoryClasses}>{cat}</h3>
            </div>
          );
        })}
        <div
          onClick={() => setBlogCategory(null)}
          x
          className="db-blog-sider__category--container cursor-pointer"
        >
          <h3 className={clearClasses}>Clear</h3>
        </div>
      </div>
    </div>
  );
};

export default BlogSider;
