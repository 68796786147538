import React, { useEffect, useContext } from "react";
import ReactContext from "../../../../../context/reactContext";
import { types } from "../../../../../data/pages/about";
import classNames from "classnames";
import "./sider-interests.scss";

const SiderInterests = () => {
  const { currentInterest, setInterestFilter, interestFilter } = useContext(ReactContext);

  let name;
  let description;
  let category;

  if (currentInterest) {
    name = currentInterest.name;
    description = currentInterest.description;
    category = currentInterest.category;
  }

  const colorMap = {
    climbing: "#FFB2CE",
    cooking: "#BDE6EE",
    family: "#FBFE67",
    crafts: "#DAC5FA",
    travel: "#F55B1C",
    music: "#029C53",
  };

  return (
    <div className="sider-interests">
      <section className="sider-interests__filter">
        {types.map((type) => {
          const buttonClasses = classNames("sider-interests__filter__button", {
            "sider-interests__filter__button--active": type === interestFilter,
          });
          return (
            <button
              className={buttonClasses}
              onClick={() =>
                setInterestFilter((prevState) =>
                  prevState === type ? null : type
                )
              }
              key={type}
            >
              {type}
            </button>
          );
        })}
      </section>
      <section className="sider-interests__hovered-interest">
        <h2 style={{ color: colorMap[category] }}>{name && name}</h2>
        <p>{description}</p>
      </section>
    </div>
  );
};

export default SiderInterests;
