import { v4 as uuidv4 } from "uuid";

export const bio = [
  "Hello! My name is Daniel and I'm a frontend software engineer and web developer with years of experience building complex web applications using the latest technologies such as React.js, Redux.js, JavaScript, TypeScript (ect.) with the ability to connect them to a Ruby on Rails or MERN style Backend.",
  "After graduating a from a highly competetive bootcamp, I got my start at a fast growing time-series database startup called Timescale where I was responsible for building and maintaining web properties, conducting A/B testing along with creating dashboards for marketing analysis, devOps, distributed design systems, and planning a rebrand.",
  "Taking what I learned from my time in the world of a tech startup and my own free time, I began to freelance for both businesses and individuals, building websites from scratch, working with world class designers, and ever expanding my knowledge and love for web development. I care deeply about function, feel, learning and implementing new best practices, and am ultimately a builder at heart.",
  "I built this website to not only display some of the work I've done, but to also be my personal sandbox for learning and practicing new skills in this wonderful craft. I’d love to hear from you if you’re starting a new venture or want a new website for your current venture. Currently a Senior Frontend Developer at ⛨Salusion!",
];

export const aboutImageData = [
  {
    imageName: "Amsterdam",
    name: "Amsterdam",
    description:
      "Amsterdam is one of my favorite cities on Earth. I had the time of my life walking around and looking at buildings all day.",
    category: "travel",
  },
  {
    imageName: "bologna",
    name: "Bologna",
    description:
      "With some of the best people I've worked with on a team offsite in Bologna!",
    category: "travel",
  },
  {
    imageName: "campfire",
    name: "Campfire songs",
    description:
      "Joshua tree is one of my favorite places to climb, camp, and play instruments.",
    category: "music",
  },
  {
    imageName: "composure",
    name: "Composure at Donner Summit",
    description: "This was my first real lead on gear!",
    category: "climbing",
  },
  {
    imageName: "doubleCross",
    name: "Double Cross",
    description: "One of my favorite climbs ever in Joshua Tree.",
    category: "climbing",
  },
  {
    imageName: "family",
    name: "My family!",
    description: "My family at my nieces 1st birthday.",
    category: "family",
  },
  {
    imageName: "hamburg",
    name: "Hamburg skyline",
    description: "View of the skyline in Hamburg, Germany.",
    category: "travel",
  },
  {
    imageName: "lisboa",
    name: "Lisbon, Portugal",
    description:
      "We came here for a marketing team offsite and it was one of the lovliest places I've ever been.",
    category: "travel",
  },
  {
    imageName: "luneburg",
    name: "Lüneburg, Germany",
    description: "A beautiful medieval city near where I lived when I was 15.",
    category: "travel",
  },
  {
    imageName: "mountains",
    name: "Wooden mountains",
    description:
      "This was really simple, but I want to learn to make more things out of wood!",
    category: "crafts",
  },
  {
    imageName: "pizza",
    name: "Burrata pizza",
    description: "One of my favorite things in the world to do is make pizza.",
    category: "cooking",
  },
  {
    imageName: "ruby",
    name: "My niece, Ruby!",
    description: "The funniest and most adorable child I know.",
    category: "family",
  },
  {
    imageName: "sandwich",
    name: "Proscuitto, burrata, and pesto sandwich in homemade focaccia",
    description:
      "Inspired by my trip to Bologna. If you ever go, head to La Prosciutteria to have your mind blown.",
    category: "cooking",
  },
  {
    imageName: "smoothAsSilk",
    name: "Smooth as Silk",
    description:
      "One of my favorite finger cracks in Joshua Tree! I almost fell here.",
    category: "climbing",
  },
  {
    imageName: "steakSalad",
    name: "Steak salad with strawberries",
    description:
      "One of my favorite simple meals. Flank steak in the oven with strawberries, carmelized onions, walnuts, and goat cheese!",
    category: "cooking",
  },
  {
    imageName: "tacos",
    name: "Oyster mushroom tacos with corn ribs and avocado gaspacho",
    description:
      "Homemade tortillas, farmers market mushrooms, and homemade pickled onions from one of my favorite cook books.",
    category: "cooking",
  },
  {
    imageName: "theEye",
    name: "The Eye",
    description:
      "Free-soloing an easy route in Joshua Tree for sunset! One of the happiest moments I can remember.",
    category: "climbing",
  },
  {
    imageName: "chasm",
    name: "The Chasm of Doom day run",
    description:
      "Normally we go through the chasm of doom at night, but had to do a day run on our last day on this trip!",
    category: "climbing",
  },
];

export const types = [
  "climbing",
  "cooking",
  "family",
  "crafts",
  "travel",
  "music",
];

export const anchorLinks = [
  {
    id: uuidv4(),
    name: "1. Introduction",
    link: "#db-about__bio",
  },
  {
    id: uuidv4(),
    name: "2. Interests",
    link: "#db-about__interests",
  },
];

export const anchorIds = ["db-about__bio", "db-about__interests"];
