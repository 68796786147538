import React, { useContext } from "react";
import { HomeSider, WorkSider, AboutSider, BlogPostSider, BlogSider, ContactSider } from "../siders";
import ReactContext from "../../context/reactContext";

const SiderSwitch = ({ pathname }) => {
  const { currentBlogPost } = useContext(ReactContext);
  const blogRouteRegex = new RegExp(`^/blog/${currentBlogPost}.*$`); 

  const routes = [
    { path: /^\/$/, component: HomeSider },
    { path: /work/, component: WorkSider },
    { path: /about/, component: AboutSider },
    { path: blogRouteRegex, component: BlogPostSider }, 
    { path: /blog/, component: BlogSider },
    { path: /contact/, component: ContactSider },
  ];

  for (let route of routes) {
    if (route.path.test(pathname)) {
      return <route.component pathname={pathname} />;
    }
  }
  return <HomeSider />;
};

export default SiderSwitch;


